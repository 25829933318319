<template>
  <br>
</template>

<script>

import {computed} from 'vue';
import {useRoute} from 'vue-router';
import {useStore} from "vuex";
import {ACT_INSERT_QR_ATTEND} from "@/store/modules/live/live";
import {useAlert} from "@/assets/js/modules/common/alert";
import {useRouter} from "vue-router/dist/vue-router";
const {showMessage} = useAlert();

export default {
  name: 'MobileLiveQrCheck',
  components: {},
  setup(){
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const aesEnc = computed(() => route.params.aesEnc);

    const getaesEncValue = () => {
      console.log(route.params.aesEnc);
      store.dispatch(`live/${ACT_INSERT_QR_ATTEND}`,
          aesEnc.value
      ).then(res => {
        if (res == "00") {
          showMessage({
            text: '이미 출석 체크를 완료했습니다.',
            callback: () => {
              router.push({name: 'Main'});
            }
          });
        } else if (res == "01") {
          showMessage({
            text: '정상적으로 출석 체크를 완료했습니다.',
            callback: () => {
              router.push({name: 'Main'});
            }
          });
        } else if (res == "02") {
          showMessage({
            text: '해당 출석체크 QR을 사용할 수 있는 날짜가 아닙니다.',
            callback: () => {
              router.push({name: 'Main'});
            }
          });
        } else if (res == "03") {
          showMessage({
            text: '해당 직원은 차수에 입과되지 않은 직원입니다.',
            callback: () => {
              router.push({name: 'Main'});
            }
          });
        } else if (res == "99") {
          showMessage({
            text: '관리자가 지정한 시간에 출석 체크 가능합니다.',
            callback: () => {
              router.push({name: 'Main'});
            }
          });
        } else {
          showMessage({
            text: '서버에 응답할 수 없습니다. 관리자에게 문의 바랍니다.',
            callback: () => {
              router.push({name: 'Main'});
            }
          });
        }
      }).catch(e => {
        console.error(e);
      })
    };

    getaesEncValue();

    return{
      getaesEncValue
    }
  }
}
</script>